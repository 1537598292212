<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("user.baseData") }}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">
          {{ $t("user.updateBaseDataAndProfilePicture") }}
        </span>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'userAccountUsers' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button v-show="saveButtonDisabled" class="btn btn-secondary">
          {{ $t("general.save") }}
        </button>
        <button
          v-show="!saveButtonDisabled"
          class="btn btn-primary"
          @click="saveuser"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h3 class="mb-10 font-weight-bold text-dark">
              {{ $t("user.profilePicture") }}:
            </h3>

            <div class="form-group row align-items-center">
              <div class="col-md-12 col-lg-12 col-xl-12">
                <ImageUploadCropper
                  aspect-ratio="1"
                  :default-image="userProfilePicture"
                  :upload-button-text="$t('user.uploadProfilePicture')"
                  :crop-button-text="$t('user.cropProfilePicture')"
                  :remove-button-text="$t('user.removeProfilePicture')"
                  @cropMode="edit = $event"
                  @fileUpdated="files = $event"
                  @fileRemoved="fileRemoved()"
                >
                </ImageUploadCropper>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <h3 class="mb-10 font-weight-bold text-dark">
              {{ $t("user.baseData") }}:
            </h3>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("user.salutation") }}
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <b-form-input
                  v-model="user.core_data.salutation"
                  class="form-control"
                  :placeholder="$t('user.enterSalutation')"
                ></b-form-input>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("user.title") }}
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <b-form-input
                  v-model="user.core_data.title"
                  class="form-control"
                  :placeholder="$t('user.enterTitle')"
                ></b-form-input>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("user.firstName") }} <span class="text-danger">*</span>
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <b-form-input
                  id="userFirstName"
                  v-model="user.core_data.firstname"
                  :placeholder="$t('user.enterFirstName')"
                  aria-describedby="userFirstName-help userFirstName-feedback"
                  trim
                  class="form-control"
                  :class="
                    !$v.user.core_data.firstname.required
                      ? 'is-invalid'
                      : 'is-valid' && !$v.user.core_data.firstname.minLength
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @input="validateFields()"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="!$v.user.core_data.firstname.required"
                  id="userLastName-feedback"
                >
                  {{ $t("validation.required.firstname") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="!$v.user.core_data.firstname.minLength"
                  id="userLastName-feedback"
                >
                  {{ $t("validation.minChars", { chars: 3 }) }}
                </b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("user.lastName") }} <span class="text-danger">*</span>
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <b-form-input
                  id="userLastName"
                  v-model="user.core_data.lastname"
                  :placeholder="$t('user.enterLastName')"
                  aria-describedby="userLastName-help userLastName-feedback"
                  trim
                  class="form-control"
                  :class="
                    !$v.user.core_data.lastname.required
                      ? 'is-invalid'
                      : 'is-valid' && !$v.user.core_data.lastname.minLength
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @input="validateFields()"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="!$v.user.core_data.lastname.required"
                  id="userLastName-feedback"
                >
                  {{ $t("validation.required.lastname") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="!$v.user.core_data.lastname.minLength"
                  id="userLastName-feedback"
                >
                  {{ $t("validation.minChars", { chars: 3 }) }}
                </b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("user.email") }} <span class="text-danger">*</span>
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <b-form-input
                  id="userEmail"
                  v-model="user.email"
                  :placeholder="$t('user.enterEmail')"
                  aria-describedby="userEmail-help userEmail-feedback"
                  trim
                  class="form-control"
                  :class="
                    !$v.user.email.required
                      ? 'is-invalid'
                      : 'is-valid' && !$v.user.email.email
                      ? 'is-invalid'
                      : 'is-valid' && !$v.user.email.isUnique
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @input="validateFields()"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="!$v.user.email.required"
                  id="userEmail-feedback"
                >
                  {{ $t("validation.required.email") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="!$v.user.email.email"
                  id="userEmail-feedback"
                >
                  {{ $t("validation.email.email") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="!$v.user.email.isUnique"
                  id="userEmail-feedback"
                >
                  {{ $t("validation.unique.email") }}
                </b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("general.active") }}
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <span class="switch switch-sm">
                  <label>
                    <input v-model="user.active" type="checkbox" />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("user.admin") }}
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <span class="switch switch-sm">
                  <label>
                    <input v-model="user.is_user" type="checkbox" />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("users.language") }}
              </label>
              <div class="col-md-9 col-lg-6 col-xl-4">
                <v-select
                  v-model="user.language_id"
                  class="form-control"
                  :items="languages"
                  item-text="label"
                  item-value="id"
                  :menu-props="{ offsetY: true }"
                />
                <b-form-text>{{ $t("users.selectLanguage") }}</b-form-text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import users from "@/components/General/UserProfile/user";
import { email, required, minLength } from "vuelidate/lib/validators";
import { bus } from "@/main";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ImageUploadCropper from "@/components/General/UserProfile/Media/ImageUploadCropper";
import Media from "@/components/General/UserProfile/Media/media";
import Swal from "sweetalert2";
import Auth from "@/components/General/Auth/auth";
import { UPDATE_USER_DATA } from "@/core/services/store/profile.module";
import { mapGetters } from "vuex";

export default {
  name: "UserProfileInformation",
  components: {
    ImageUploadCropper
  },
  validations: {
    user: {
      core_data: {
        firstname: { required, minLength: minLength(3) },
        lastname: { required, minLength: minLength(3) }
      },
      email: {
        email,
        required,
        isUnique(value) {
          return !this.currentUsers.includes(value);
        }
      }
    }
  },
  computed: {
    ...mapGetters(["languages"])
  },
  data() {
    return {
      isBusy: false,
      saveButtonDisabled: false,
      files: [],
      edit: false,
      userProfilePicture: "",
      user: {
        name: "",
        email: "",
        active: false,
        language_id: "",
        profile_picture_id: "",
        core_data: {
          salutation: "",
          title: "",
          firstname: "",
          lastname: ""
        }
      },
      currentUsers: [],
      oldProfilePictureId: ""
    };
  },
  mounted() {
    this.loaduser();
    this.setBreadcrumb();
  },
  methods: {
    fileRemoved() {
      this.files = [];
      this.userProfilePicture = false;
      this.user.profile_picture_id = null;
    },
    validateFields() {
      this.$v.$touch();
      this.saveButtonDisabled = this.$v.$invalid;
    },
    loaduser() {
      var userId = this.$store.getters.userData.id;
      users.get(userId)
        .then(response => {
          this.setuser(response.data.data);
          this.validateFields();
        })
        .catch(e => {
          console.log(e);
        });
    },
    setuser(user) {
      this.user = user;
      this.user.language_id = user.language.id;
      this.oldProfilePictureId = user.profile_picture_id;

      if (user.core_data === null) {
        this.user.core_data = {
          salutation: "",
          title: "",
          firstname: "",
          lastname: ""
        };
      }

      if (user.profile_picture && user.profile_picture.length !== 0) {
        this.userProfilePicture = user.profile_picture.link;
      }
    },
    deleteProfilePicture(mediaId) {
      if (mediaId !== null) {
        Media.delete(mediaId);
      }
    },
    async saveuser() {
      this.isBusy = true;
      this.user.name =
        this.user.core_data.firstname + " " + this.user.core_data.lastname;

      if (this.files.length) {
        let mediaData = new FormData();
        mediaData.append("files[]", this.files[0].file);
        mediaData.append(
          "description[]",
          "user Profile Picture - " + this.user.name
        );
        await Media.store(mediaData)
          .then(response => {
            if (response.data.data.success) {
              this.user.profile_picture_id = response.data.data.uploaded[0].id;
            }
          })
          .catch(error => {
            Swal.fire({
              title: this.$t("general.caution"),
              text: error.response.data.message,
              icon: "error"
            });
            this.isBusy = false;
          });
      }

      users.update(this.user.id, this.user)
        .then(response => {
          if (this.files.length || !this.user.profile_picture_id) {
            this.deleteProfilePicture(this.oldProfilePictureId);
          }
          if (
            this.user.id === this.$store.getters.currentUserPersonalInfo.id
          ) {
            let user = response.data.data;
            this.setuser(user);
            this.$store.dispatch(UPDATE_USER_DATA, user);
            Auth.updateUserData(user);
          }
          this.$toast.fire({
            icon: "success",
            title: this.$t("user.userUpdated")
          });
          this.isBusy = false;
          bus.$emit("userUpdated");
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    setBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("user.myProfile"),
          route: ""
        },
        {
          title: this.$t("users.baseData"),
          route: ""
        }
      ]);
    }
  }
};
</script>
